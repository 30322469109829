import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Header from '../components/Header'
import Layout from '../layouts'

import Aos from 'aos'
import 'aos/dist/aos.css'

function ServicePage({ location, data }) {
  useEffect(() => {
    Aos.init({ duration: 700 })
  }, [])
  const {
    header,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
  } = data.markdownRemark.frontmatter
  return (
    <Layout location={location}>
      <div className="animPage">
        <Header title={<span>{header.title}</span>} />
        <section id="one" className="main style1 special">
          <header className="major ">
            <h2>{section1.title}</h2>
          </header>
          <div className="grid-wrapper">
            <div className="col-12 group">
              <div
                data-aos="fade-left"
                style={{ textAlign: 'left', marginTop: 10 }}
              >
                <img
                  style={{
                    position: 'absolute',
                    width: 80,
                    marginTop: '-20px',
                  }}
                  src={section2.icon}
                  alt=""
                />
                <h2 style={{ marginLeft: 110 }}>{section2.title}</h2>
                <p>{section2.paragraph}</p>
                <p className="before2">{section2.paragraph1}</p>
                <p className="before2">{section2.paragraph2}</p>
                <p className="before2">{section2.paragraph3}</p>
                <p className="before2">{section2.paragraph4}</p>
                <p className="before2">{section2.paragraph5}</p>
                <p className="before2">{section2.paragraph6}</p>
                <p className="before2">{section2.paragraph7}</p>
                <p className="before2">{section2.paragraph8}</p>
                <p className="before2">{section2.paragraph9}</p>
                <p className="before2">{section2.paragraph10}</p>
                <p className="before2">{section2.paragraph11}</p>
                <p className="before2">{section2.paragraph12}</p>
              </div>
            </div>

            <div className="col-12 group">
              <div data-aos="fade-left" style={{ textAlign: 'left' }}>
                <img
                  style={{
                    position: 'absolute',
                    width: 80,
                    marginTop: '-20px',
                  }}
                  src={section3.icon}
                  alt=""
                />
                <h2 style={{ marginLeft: 110 }}>{section3.title}</h2>
                <div style={{ marginTop: 80 }}>
                  <p className="before2">{section3.paragraph1}</p>
                  <p className="before2">{section3.paragraph2}</p>
                  <p className="before2">{section3.paragraph3}</p>
                  <p className="before2">{section3.paragraph4}</p>
                </div>
              </div>
            </div>

            <div className="col-12 group">
              <div data-aos="fade-left" style={{ textAlign: 'left' }}>
                <img
                  style={{
                    position: 'absolute',
                    width: 80,
                    marginTop: '-20px',
                  }}
                  src={section4.icon}
                  alt=""
                />
                <h2 style={{ marginLeft: 110 }}>{section4.title}</h2>
                <div style={{ marginTop: 80 }}>
                  <p className="before2">{section4.paragraph1}</p>
                  <p className="before2">{section4.paragraph2}</p>
                  <p className="before2">{section4.paragraph3}</p>
                </div>
              </div>
            </div>

            <div className="col-12 group">
              <div data-aos="fade-left" style={{ textAlign: 'left' }}>
                <img
                  style={{
                    position: 'absolute',
                    width: 80,
                    marginTop: '-20px',
                  }}
                  src={section5.icon}
                  alt=""
                />
                <h2 style={{ marginLeft: 110 }}>{section5.title}</h2>
                <div style={{ marginTop: 80 }}>
                  <p className="before2">{section5.paragraph}</p>
                </div>
              </div>
            </div>

            <div className="col-12 group">
              <div data-aos="fade-left" style={{ textAlign: 'left' }}>
                <img
                  style={{
                    position: 'absolute',
                    width: 80,
                    marginTop: '-20px',
                  }}
                  src={section6.icon}
                  alt=""
                />
                <h2 style={{ marginLeft: 110 }}>{section6.title}</h2>
                <div style={{ marginTop: 50 }}>
                  <p className="before2">{section6.paragraph}</p>
                  <p className="before2">{section6.paragraph1}</p>
                  <p className="before2">{section6.paragraph2}</p>
                  <p className="before2">{section6.paragraph3}</p>
                  <p className="before2">{section6.paragraph4}</p>
                  <p className="before2">{section6.paragraph5}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicePage

export const servicePageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header {
          title
        }
        section1 {
          title
          paragraph
        }
        section2 {
          icon
          title
          paragraph
          paragraph1
          paragraph2
          paragraph3
          paragraph4
          paragraph5
          paragraph6
          paragraph7
          paragraph8
          paragraph9
          paragraph10
          paragraph11
          paragraph12
        }
        section3 {
          icon
          title
          paragraph1
          paragraph2
          paragraph3
          paragraph4
        }
        section4 {
          icon
          title
          paragraph1
          paragraph2
          paragraph3
        }
        section5 {
          icon
          title
          paragraph
        }
        section6 {
          icon
          title
          paragraph
          paragraph1
          paragraph2
          paragraph3
          paragraph4
          paragraph5
        }
      }
    }
  }
`
